
import { ObserveVisibility } from 'vue-observe-visibility'
import Drawer from '@/components/landing/Drawer.vue'
import Footer from '@/components/landing/Footer.vue'
import SocialLinks from '@/components/landing/SocialLinks.vue'
import FlyoutMenu from '@/components/landing/FlyoutMenu.vue'
import Button from '@/components/UntitledUI/Button.vue'
import Icon from '@/components/UntitledUI/Icon.vue'
import SectionHeader from '@/components/landing/SectionHeader.vue'
import Splide from '@/components/landing/Splide.vue'
import AnimatedNumbers from '@/components/landing/AnimatedNumbers.vue'
import { event, init } from '@/Utils/SnapPixel'
import LandingFooter from '@/components/landing/LandingFooter.vue'
import LandingHeader from '@/components/landing/LandingHeader.vue'
import companies from '@/Utils/landing/companies'
import numbers from '@/Utils/landing/numbers'

export default {
    layout: 'landing',
    directives: { ObserveVisibility },
    components: {
        LandingHeader,
        LandingFooter,
        FlyoutMenu,
        SocialLinks,
        Footer,
        Drawer,
        Splide,
        SectionHeader,
        Icon,
        Button,
        AnimatedNumbers
    },
    provide () {
        return {
            registerUrl: this.registerUrl
        }
    },
    auth: 'guest',
    data () {
        return {
            showNumbers: false,
            showDrawer: false,
            showProducts: false,
            faqs: [
                {
                    quest: this.$t('ماذا يميّز قانونية عن غيرها؟'),
                    answer: this.$t('قانونية أكبر قاعدة بيانات قانونية سعودية، جمعت أكثر من 60 موقع في مكان واحد!'),
                    locales: ['ar', 'en']
                },
                {
                    quest: this.$t('هل يتم تحديث المستندات في قانونية بشكل فوري؟'),
                    answer: this.$t('نعم، فريقنا يعمل على إضافة التحديثات والتعديلات بشكل فوري، مع إضافة تاريخ التعديل أو التحديث.'),
                    locales: ['ar', 'en']
                },
                {
                    quest: this.$t('هل جميع السوابق القضائية منشورة؟'),
                    answer: this.$t('نعم، تتميّز قانونية بجمعها لجميع السوابق القضائية المنشورة، كالأحكام الإدارية، العمالية، التجارية، العامة، الجزائية، التأمينية، الضريبية والجمركية والزكوية، وغيرها من الأحكام.'),
                    locales: ['ar', 'en']
                },
                {
                    quest: this.$t('هل تحتوي منصة قانونية على كتب ومصادر ثانوية؟'),
                    answer: this.$t('بكل تأكيد! منصّة قانونية تجمع ما يزيد عن 180 كتاب بالإضافة إلى أبحاث ومقالات وشروحات تساعدك على الوصول للمعلومة بشكل أسرع.'),
                    locales: ['ar']
                },
                {
                    quest: this.$t('هل يمكنني إضافة تعليقي الخاص على المستندات؟'),
                    answer: this.$t('من مزايا منصة قانونية سهولة التعامل مع النص! وبكل سهولة يمكنك تحديد النص المطلوب وتظليله وإضافة ملاحظتك أو تعليقك وسيتم جمعها في " تحديداتي وملاحظاتي ".'),
                    locales: ['ar']
                },
                {
                    quest: this.$t('كيف أبحث عن المستندات في قانونية؟'),
                    answer: this.$t('يمكنك تجربة كتابة "نظام العمل" في محرك البحث، وسيظهر لك في أول نتيجة نظام العمل مرتبط بلائحته التنفيذية وبترجمة المواد، وستجد بجانب كل مادة المبادئ العمالية التي ناقشتها.'),
                    locales: ['ar', 'en']
                },
                {
                    quest: this.$t('هل يمكنني البحث عن الأوامر والتعاميم في قانونية؟'),
                    answer: this.$t('بكل تأكيد! منصة قانونية تمكنّك من الوصول إلى عدد كبير من الأوامر والتعاميم، ويمكنك البحث عنها بأحد الطرق التالية: رقم المستند، تاريخ المستند، الجهة المصدّرة للمستند، أي عبارة ذُكرت داخل المستند.'),
                    locales: ['ar']
                },
                {
                    quest: this.$t('كيف أبحث عن السوابق القضائية؟'),
                    answer: this.$t('يمكنك البحث عن السوابق القضائية بالكلمات المفتاحية، أو موضوع قضية، أو كتابة رقم المادة واسم النظام في محرّك البحث، للتجربة اكتب: المادة ٦٧ نظام المرافعات الشرعية، وستظهر لك السوابق القضائية التي ناقشت المادة.'),
                    locales: ['ar']
                },
                {
                    quest: this.$t('هل توفرون خدمات تقسيط الاشتراك؟'),
                    answer: this.$t('نعم، يمكنك تقسيم اشتراكك على 4 دفعات مع تمارا.'),
                    locales: ['ar']
                },
                {
                    quest: this.$t('كيف يمكنني التواصل معكم؟'),
                    answer: ` ${this.$t('نسعد ونتشرف بك، ويمكنك التواصل معنا عبر الرابط')} ( <a class="text-blue-500 underline" href="${this.localePath('/ContactUs')}">${this.$t('اضغط هنا')}</a> ).`,
                    locales: ['ar', 'en']
                }
            ],
            tweets: [
                {
                    message: 'شخصياً، استخدم منصة @qanoniah اقرأ النظام واللوائح او القرارات المرتبطة فيه واذا النظام قديم وفيه أحكام  قضائية صدرت تطبيقاً له اقرأها برضوا. واذا استصعب علي الأمر وصرت احتاج احد يشرح لي افتح قناة الدكتور محمود عمر @drmahmoudomar على اليوتيوب واتابعه',
                    name: 'Yousef',
                    career: 'ممارس قانوني',
                    link: 'https://twitter.com/lly722/status/1706273163774992775?s=46'
                }, {
                    message: 'لكثرة الأسئلة وعدم وضوح المعلومة لجميع الطلاب، أرفق لكم هذا المقطع حيثُ شرحت فيه  واجب القانون الجنائي الخاص (15درجة) بالتفصيل.-اسم المنصة التي استخدمتها (قانونية)،أنصح بتجربتها حيث فيها الكثير من السوابق القضائية  (المنصة برسوم وليست مجانية) @qanoniah',
                    name: 'مـازن الشـيخي',
                    career: 'طالب قانون في #KFU',
                    link: 'https://twitter.com/Mazen_Alshaikhy/status/1708874174838493408?s=20'
                }, {
                    message: '@qanoniah اشتركت في منصة قانونية لمدة شهر وانبهرت حقيقة من كمية المعلومات المتوفرة من كتب واحكام ومذكرات تخدم موضوع بحثي بدقائق معدودة انصحكم بها',
                    name: 'بشاير المطلق',
                    career: 'محامية مرخصة ومعتمدة من @SAUDI_SBA',
                    link: 'https://twitter.com/lawbesh/status/1729566945748435141?s=46'
                },
                {
                    message: 'شكر خاص لمنصة قانونية على رعايتهم لبرنامج مُدارسة نظام الشركات السعودي، بالإضافة إلى تقديمهم اشتراك مجاني لمدة شهر لجميع المشاركين في برنامج المدارسة @qanoniah شخصيا استخدم منصة قانونية كخياري الأول في البحث عن الأنظمة والمستندات القانونية الخاصة بالنظام السعودي لأكثر من عام.',
                    name: 'عبدالرحمن مشاط',
                    career: 'مؤسس @Beyondlawsa',
                    link: 'https://twitter.com/akmashat/status/1653761203485696002?s=46'
                }, {
                    message: 'يستاهلون وشهادتي فيكم يا "قانونية" مجروحة لانكم رائعين جدًا ما شاء الله والخدمات اللي توفرونها تُسهل وتُيسر على القانوني امور بحثه وعمله وقضاياه وعقوده وفيه مزايا وامور كل يوم اتعرف عليها مع العلم اني لي شهور مشترك عندكمبالتوفيق للجميع',
                    name: 'محمد جَهز',
                    career: 'محام',
                    link: 'https://twitter.com/lawyermohammedh/status/1726951567839088953?s=46'
                }
            ],
            numbers: [],
            companies: [],
            featuresOverview: [
                {
                    title: this.$t('ربط الأنظمة بلوائحها التنفيذية'),
                    desc: this.$t('ربط النظام بلائحته التنفيذية لتمكن الباحث من استعراضهما معاً في الوقت نفسه.'),
                    alt: 'Regulations And Explanations Linked Overview',
                    src: `/landing/overview/${this.$helpers.getLocale()}/regs_and_exps.webp`,
                    locales: ['ar', 'en']
                },
                {
                    title: this.$t('مستندات محدثة'),
                    desc: this.$t('تصدر تحديثات وتعديلات عديدة على الأنظمة، وهنا تكمن مهمة قانونية بتسهيل الوصول إلى هذه المستجدات بتفاصيلها، وذلك بتوفير سجل كامل بجميع التعديلات الواردة عليها.'),
                    alt: 'Updated Documents Overview',
                    src: `/landing/overview/${this.$helpers.getLocale()}/history.webm`,
                    mp4src: `/landing/overview/${this.$helpers.getLocale()}/history.mp4`,
                    poster: `/landing/overview/${this.$helpers.getLocale()}/history.webp`,
                    isRounded: true,
                    isVideo: true,
                    locales: ['ar', 'en']
                },
                {
                    title: this.$t('خيارات بحث متقدمة مع إمكانية التخصيص'),
                    desc: this.$t('تمكّن من الوصول إلى المعلومات والمستندات القانونية بشكل دقيق وسريع.'),
                    alt: 'Search Options Overview',
                    src: `/landing/overview/${this.$helpers.getLocale()}/search_options.png`,
                    locales: ['ar', 'en'],
                    extraPadding: true
                },
                {
                    title: this.$t('التظليل وتدوين الملاحظات'),
                    desc: this.$t('تمكنك قانونية من تدوين ملاحظاتك وتعليقاتك على المستندات مع إمكانية الرجوع إليها بكل سهولة.'),
                    alt: 'Highlight And Note Overview',
                    src: `/landing/overview/${this.$helpers.getLocale()}/hxn.webm`,
                    mp4src: `/landing/overview/${this.$helpers.getLocale()}/hxn.mp4`,
                    poster: `/landing/overview/${this.$helpers.getLocale()}/hxn.webp`,
                    isRounded: true,
                    isVideo: true,
                    locales: ['ar', 'en']
                },
                {
                    title: this.$t('المناقشات بين فريق العمل'),
                    desc: this.$t('لرفع جودة الاحترافية والمهنية، شارك وناقش مع فريق عملك المعلومة القانونية مباشرةً من خلال منصة قانونية.'),
                    alt: 'Chatting With Team',
                    src: `/landing/overview/${this.$helpers.getLocale()}/chat.webm`,
                    mp4src: `/landing/overview/${this.$helpers.getLocale()}/chat.mp4`,
                    poster: `/landing/overview/${this.$helpers.getLocale()}/chat.webp`,
                    isRounded: true,
                    isVideo: true,
                    locales: ['ar', 'en']
                },
                {
                    title: this.$t('مجلداتي'),
                    desc: this.$t('أنشئ مجلداتك الخاصة بك وأضف لها جميع المستندات ذات الأهمية، وارجع لها بكل سهولة في أي وقت.'),
                    alt: 'My folders System Overview',
                    src: `/landing/overview/${this.$helpers.getLocale()}/myfolders.png`,
                    locales: ['ar', 'en'],
                    extraPadding: true
                },
                {
                    title: this.$t('خيارات متنوعة من اللغة الانجليزية'),
                    desc: this.$t('توفر لك قانونية تجربة متنوعة من خيارات اللغة الإنجليزية الرسمية المعتمدة من قبل الجهات الرسمية، بالإضافة إلى الترجمة المدعومة بالذكاء الاصطناعي. مما يوفر لك مستوى عالٍ من الدقة والموثوقية'),
                    alt: 'My folders System Overview',
                    src: `/landing/overview/${this.$helpers.getLocale()}/translation.webp`,
                    locales: ['ar', 'en'],
                    extraPadding: true
                },
                {
                    title: this.$t('ابق على اطلاع بالقوانين السعودية'),
                    desc: this.$t('كن مطلع على آخر تحديثات القوانين واللوائح السعودية مباشرة من خلال ميزة الإشعارات عن طريق إشعارات الرسائل النصية و المنصة و البريد الإلكتروني ، مما يبقيك على اطلاع دائم على التحديثات'),
                    alt: 'My folders System Overview',
                    src: `/landing/overview/${this.$helpers.getLocale()}/updated.webp`,
                    locales: ['ar', 'en'],
                    extraPadding: true
                }
            ],
            keyFeatures: [
                {
                    title: this.$t('الأنظمة ولوائحها'),
                    desc: this.$t('تجد في مكان واحد جميع الأنظمة السعودية مقترنةً بلوائحها التنفيذية وبآخر التحديثات والتعديلات الصادرة عليها'),
                    icon: 'document.svg'
                },
                {
                    title: this.$t('السوابق القضائية'),
                    desc: this.$t('الاطلاع على آلاف من الأحكام القضائية في المملكة العربية السعودية مع إمكانية البحث بموضوع النزاع'),
                    icon: 'hammer.svg'
                },
                {
                    title: this.$t('الأوامر والتعاميم'),
                    desc: this.$t('الاطلاع على آلاف الأوامر والقرارات بوثائقها الأصلية من جميع الجهات الحكومية مع إمكانية البحث في محتوى المستند'),
                    icon: 'cert.svg'
                },
                {
                    title: this.$t('الكتب والأبحاث القانونية'),
                    desc: this.$t('تصفّح العديد من الكتب والأبحاث القانونية والمصادر الثانوية المتنوعة والمثرية'),
                    icon: 'book.svg'
                }
            ],
            posts: [],
            fadeInterval: null
        }
    },
    computed: {
        localizedFaqs () {
            return this.faqs.filter(faq => faq.locales.includes(this.$helpers.getLocale()))
        },
        localizedFeaturesOverview () {
            return this.featuresOverview.filter(feature => feature.locales.includes(this.$helpers.getLocale()))
        },
        registerUrl () {
            return this.$helpers.getLocale() === 'ar'
                ? this.localePath('/register')
                : 'https://share-eu1.hsforms.com/1DTevyvHaSI-uDmgUk6kPHAfbqtk'
        }
    },
    watch: {
        $route () {
            this.showDrawer = false
            this.$nextTick(() => {
                if (!(this.$route.hash && this.$route.hash.length)) {
                    return
                }
                this.$el.querySelector(this.$route.hash)?.scrollIntoView()
            })
        }
    },
    created () {
        this.numbers = numbers.map(n => ({ ...n, title: this.$t(n.title), prefix: n.prefix ? this.$t(n.prefix) : undefined }))
        this.companies = companies
        // this.posts = posts
    },
    mounted () {
        try {
            init(this.$auth.user?.email)
            event('track', 'PAGE_VIEW')
        } catch (e) {
        }
    },
    head () {
        return {
            titleTemplate: this.$t('قانونية'),
            meta: [
                {
                    name: 'description',
                    content: this.$t('قانونية واحدة من أكبر قواعد البيانات القانونية في المملكة العربية السعودية، تجمع أكثر من 60 موقع قانوني في مكان واحد، وتوفر الوصول السريع والموثوق للمعلومات القانونية المتنوعة.')
                }
            ]
        }
    }
}
